<template>
    <!-- 分销list -->
    <div class="score">
        <el-form class="page-search" :inline="true" label-width="100px">
            <div class="left">
                <el-form-item label="">
                    <el-select v-model="orderFrom" clearable placeholder="请选择类型">
                        <el-option
                            v-for="item in orderFromOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="">
                    <el-input v-model="fromTitle" placeholder="积分来源" clearable></el-input>
                </el-form-item>
                <el-form-item label="">
                    <el-input v-model="remark" placeholder="备注" clearable></el-input>
                </el-form-item>
                <el-form-item label="">
                    <el-date-picker
                        v-model="dateRange"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        :clearable="true"
                    >
                    </el-date-picker>
                </el-form-item>
            </div>
            <div class="right">
                <div class="search-btn">
                    <div class="custom-btn">
                        <el-button type="primary" @click="querydata">查询</el-button>
                        <el-button @click="reset">重置</el-button>
                    </div>
                </div>
            </div>
        </el-form>
        <div class="page-content">
            <gb-table
                :table="table"
                :is-copy="true"
                :table-height="500"
                :row-key="getRowKey"
                :is-show-pagination="true"
                @pageChange="pageChange"
                @sizeChange="sizeChange"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'Score',
    data () {
        return {
            table: {
                total: 0,
                currentPage: 1,
                pageSize: 20,
                data: [],
                // 标题
                column: [
                    {
                        prop: 'orderFrom',
                        label: '类型',
                        minWidth: '100',
                        formatter: (data) => {
                            return this.orderFromOptions.filter(
                                (item) => item.value === data.orderFrom
                            )[0].label
                        }
                    },
                    { prop: 'fromTitle', label: '积分来源', minWidth: '100', showTip: true },
                    { prop: 'orderScore', label: '预计获得积分', minWidth: '50' },
                    { prop: 'realScore', label: '实际获得积分', minWidth: '50' },
                    {
                        prop: 'scoreTime',
                        label: '时间',
                        minWidth: '100',

                        formatter: (row) => {
                            return row.scoreTime ? this.$moment(row.scoreTime).format('YYYY-MM-DD') : ''
                        }
                    },
                    { prop: 'remark', label: '备注', minWidth: '100' }
                ]
            },
            // 查询条件数据
            fromTitle: '',
            orderFrom: '',
            remark: '',
            dateRange: ['', ''],
            orderFromOptions: [
                {
                    label: '培训课程',
                    value: 0
                },
                {
                    label: '比赛',
                    value: 1
                },
                {
                    label: '奖惩',
                    value: 2
                }
            ]
        }
    },
    created () {
        this.initData()
    },
    methods: {
        initData () {
            this.$axios
                .fetch(
                    'learningServer',
                    '/learning/learning-order-page',
                    {
                        fromTitle: this.fromTitle,
                        orderFrom: this.orderFrom,
                        remark: this.remark,
                        size: this.table.pageSize,
                        current: this.table.currentPage,
                        selectFrom: this.dateRange && this.dateRange[0] ? this.dateRange[0] + ' 00:00:00' : null,
                        selectTo: this.dateRange && this.dateRange[1] ? this.dateRange[1] + ' 23:59:59' : null
                    }
                )
                .then((res) => {
                    // console.log(res)
                    this.table.data = res.data
                    this.table.total = res.total
                })
        },
        // 获取列表数据的唯一标识
        getRowKey (row) {
            return row.id
        },
        pageChange (val) {
            this.table.currentPage = val
            this.initData()
        },
        sizeChange (val) {
            this.table.currentPage = 1
            this.table.pageSize = val
            this.initData()
        },
        /* 查询第几页数据 */
        handleCurrentChange (val) {
            this.searchForm.pageIndex = val
            this.initData(this.searchForm)
        },
        handlesSizeChange (val) {
            this.currentPage = 1
            this.searchForm.pageIndex = 1
            this.searchForm.pageSize = val
            this.initData(this.searchForm)
        },
        querydata () {
            this.table.currentPage = 1
            this.initData()
        },
        contentChange () {
            this.conceal = !this.conceal
        },
        reset () {
            this.fromTitle = this.orderFrom = this.remark = ''
            this.dateRange = ['', '']
            this.table.currentPage = 1
            this.initData()
        }
    }
}
</script>
<style lang="less" scoped>
</style>
